import lexie from './img/lexie.png';
import './App.css';
import PasswordService from './services/password-service';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PasswordCheck />} />
          <Route path="home" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

class PasswordCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideError: true
    }
    this.checkPassword = this.checkPassword.bind(this);
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
  }

  onKeyPressHandler(event) {
    if (event && event.charCode === 13) {
      this.checkPassword();
    }
  }

  checkPassword() {
    let password = document.getElementById("passwordInput").value;
    let passwordService = new PasswordService();
    passwordService.checkPassword(password).then(function(event){
      if (event && event.data && event.data.data) {
        window.localStorage.setItem('password', password);
        window.location.href = '/home';
      } else {
        this.setState({
          authenticated: false,
          hideError: false,
          password: password
        });
      }
    }.bind(this));
  }

  render() {
    return (
      <div>
        <div className="password-box-wrapper">
          <div className="password-box-image-wrapper">
            <img className="lexie-logo" src={lexie} alt="lexie" />
          </div>
          <div className="password-box-title-wrapper">
            Lexie's Letters
          </div>
          <div className="password-input-wrapper">
            <div hidden={this.state.hideError} className="password-error-message-wrapper">
              Incorrect password :/, try again...
            </div>
            <input id="passwordInput" className="password-input" placeholder="Password" onKeyPress={this.onKeyPressHandler.bind(this)}></input>
          </div>
        </div>
      </div>
    )
  }
}

export default App;