const axios = require('axios').default;

class PasswordService {
    checkPassword(password){
        if (password) {
            return axios.get('https://wknlmse3rlyor4a5bfuadc4l6m0gfycs.lambda-url.us-east-1.on.aws?password=' + password);
        } else {
            return false;
        }
    }
}

export default PasswordService;