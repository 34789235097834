import React from 'react';
import LetterService from './../services/letter-service';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import PasswordService from './../services/password-service';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import './../css/Home.css';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.letterService = new LetterService();
        this.state = {
            letters: [],
            letterList: [],
            letterDate: "",
            letterContent: "",
            letterHidingSpot: "",
            letterOpen: false,
            unlockLetterOpen: false,
            isValidLetterCode: false,
            showInvalidLetterCodeError: false
        }

        this.openUnlockLetterModal = this.openUnlockLetterModal.bind(this);
        this.openLetter = this.openLetter.bind(this);
        this.unlockLetter = this.unlockLetter.bind(this);
        this.toggleLetter = this.toggleLetter.bind(this);
        this.toggleUnlockLetter = this.toggleUnlockLetter.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        let cookie = window.localStorage.getItem('password');
        let passwordService = new PasswordService();
        if (cookie){
            passwordService.checkPassword(cookie).then(function(event){
                if (event && event.data && event.data.data) {
                    this.loadData();
                } else {
                    window.localStorage.removeItem('password');
                    window.location.href = '/';
                }
            }.bind(this));
        } else {
            window.localStorage.removeItem('password');
            window.location.href = '/';
        }
    }

    loadData() {
        this.letterService.getUnlockedLetters().then(function(event){
            let letters = event.data.data.Items;
            let letterList = [];
            for (let i in letters) {
                let letter = letters[i];
                let item = (
                    <div className="letter-wrapper" key={letter.letterCode.S} onClick={this.openLetter.bind(this, letter)}>
                        <div className="letter-created-date">
                            {letter.createdDate.S}
                        </div>
                        <div className="letter-created-date">
                            {letter.hidingSpot.S}
                        </div>
                    </div>
                )
                letterList.push(item);
            }
            this.setState({
                letters: letters,
                letterList: letterList
            });
        }.bind(this));
    }
  
    render() {
      return (
        <div>
            <div className="home-header">
                <div className="app-title">
                    Lexie's Letters
                </div>
                <div className="unlock-button-wrapper" onClick={this.openUnlockLetterModal}>
                    <LockOpenIcon/>
                </div>
            </div>
            {this.state.letterList}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.letterOpen}
                onClose={this.toggleLetter}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.state.letterOpen}>
                    <div className="letter">
                        <div hidden={!this.state.isValidLetterCode} className="letter-code-error-message-wrapper">
                            You unlocked a new letter :)
                        </div>
                        <div className="letter-letter-date">
                            {this.state.letterDate} - {this.state.letterHidingSpot}
                        </div>
                        <div className="letter-letter-content">
                            {this.state.letterContent}
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.unlockLetterOpen}
                onClose={this.toggleUnlockLetter}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={this.state.unlockLetterOpen}>
                    <div className="unlock-letter-wrapper">
                        <div className="unlock-letter-modal-title">Unlock a Letter</div>
                        <input id="unlockLetterInput" className="unlock-letter-input" onKeyPress={this.onUnlockLetterHandler.bind(this)}></input>
                        <div hidden={!this.state.showInvalidLetterCodeError} className="letter-code-error-message-wrapper">
                            Letter not found, try again :/
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
      )
    }

    toggleLetter(){
        this.setState({
            letterOpen: !this.state.letterOpen,
            isValidLetterCode: false
        });
    }

    toggleUnlockLetter(){
        this.loadData();
        this.setState({
            unlockLetterOpen: !this.state.unlockLetterOpen,
        });
    }

    openLetter(event) {
        this.setState({
            letterDate: event.createdDate.S,
            letterContent: event.content.S,
            letterHidingSpot: event.hidingSpot.S,
            letterOpen: true
        });
    }

    openUnlockLetterModal() {
        this.setState({
            unlockLetterOpen: true,
            showInvalidLetterCodeError: false
        });
    }

    unlockLetter(code) {
        this.letterService.unlockLetter(code).then(function(event){
            if (event.data.data){
                if (event.data.data.letterNotFound) {
                    this.setState({
                        isValidLetterCode: false,
                        showInvalidLetterCodeError: true
                    });
                } else {
                    this.setState({
                        isValidLetterCode: true,
                        showInvalidLetterCodeError: false
                    });
                    this.toggleUnlockLetter();
                    this.openLetter(event.data.data.letter);
                }
            }
        }.bind(this));
    }

    onUnlockLetterHandler(event){
        if (event && event.charCode === 13) {
            this.unlockLetter(document.getElementById("unlockLetterInput").value);
        }
    }
}

export default Home;