const axios = require('axios').default;

class LetterService {
    constructor(){
        this.unlockedLettersCache = undefined;
    }

    getUnlockedLetters(){
        this.unlockedLettersCache = axios.get('https://3rvzpv5fzibkj5vrrz6ai7o3s40rjbmd.lambda-url.us-east-1.on.aws/');
        return this.unlockedLettersCache;
    }

    unlockLetter(code){
        if (code) {
            return axios.get('https://rpiqrzltpalixm54mwm2lazzgy0wrnju.lambda-url.us-east-1.on.aws/?letterCode=' + code);
        } else {
            return false;
        }
    }
}

export default LetterService;